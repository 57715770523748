import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UtilsString } from '@/utils/utils-string';
import { ssmLoadingService } from './loading-service';
import { MessageService } from './message-service';
import { ssmTokenService } from './token-service';
import router from '@/router/router';
import { RouterNames } from '@/router/routernames';
import { ApiCodesResponse } from './ApiCodesResponse';

export default class HttpService {

    private axiosInstance: AxiosInstance = this.initAxiosInstance();

    public initAxiosInstance(): AxiosInstance {
        // Creamos la instancia de axios
        return axios.create({
            headers: this.composeHeaders(),
        });
    }

    public composeHeaders() {
        if (ssmTokenService.hasToken()) {
            return { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + ssmTokenService.getToken(), 'Access-Control-Allow-Origin': 'http://localhost:8080/' };
        } else {
            return { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': 'http://localhost:8080/', 'Access-Control-Allow-Credentials': 'true' };
        }
    }

    public composeHeadersForFile() {
        if (ssmTokenService.hasToken()) {
            return { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + ssmTokenService.getToken(), 'Access-Control-Allow-Origin': 'http://localhost:8080/' };
        } else {
            return { 'Content-Type': 'multipart/form-data', 'Access-Control-Allow-Origin': 'http://localhost:8080/', 'Access-Control-Allow-Credentials': 'true' };
        }
    }

    public updateTokenHeader() {
        this.axiosInstance.defaults.headers = this.composeHeaders();
    }

    // Método para hacer una petición 'GET' al API
    //   - url: la url completa
    //   - params: parámetros
    //   - fullResponse: por defecto se devuelve solo el 'data' resultado de la petición,
    //                   pero se puede indicar false para obtener la Response entera
    //   - showMessageError: muestra un toast con el error
    public get(url: string, params?: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.get(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }
  // Método para hacer una petición 'POST' al API
    // (ver la explicación de los parámetros en el método 'get')
    public postfile(url: string, params: FormData, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return axios.create({
            headers: this.composeHeadersForFile(),
        }).post(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }
    // Método para hacer una petición 'POST' al API
    // (ver la explicación de los parámetros en el método 'get')
    public post(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.post(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para hacer una petición 'PUT' al API
    // (ver la explicación de los parámetros en el método 'get')
    public put(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.put(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para hacer una petición 'DELETE' al API
    // (ver la explicación de los parámetros en el método 'get')
    public delete(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.delete(url, params);
    }

    // Método para hacer una petición 'PATCH' al API
    // (ver la explicación de los parámetros en el método 'get')
    public patch(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.patch(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para recuperar la instancia de axios
    // usado por jest en testing
    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }

    private processResponse(response: AxiosResponse, showLoading: boolean, fullResponse: boolean = false) {
        if (showLoading) {
            ssmLoadingService.disableLoading();
        }
        return fullResponse ? response : response.data;
    }

    private processError(error: any, showLoading: boolean, showMessageError: boolean = false) {
        if (showLoading) {
            ssmLoadingService.disableLoading();
        }
        try {
            console.log(error.response);
            switch (error.response.status) {
                case ApiCodesResponse.UNAUTHORIZED: {
                    ssmTokenService.clearToken();
                    router.push({ name: RouterNames.Login });
                    MessageService.toast('Inicie sesión', MessageService.TypeWarning);
                    break;
                }
                case ApiCodesResponse.METHOD_NOT_ALLOWED:{
                    MessageService.toast(error.response.data.message, MessageService.TypeWarning);
                }
            }
        } catch (e) { }
        const message = error.message || error.response.statusText;

        if (!UtilsString.IsNullOrWhiteSpace(message) && showMessageError) {
            MessageService.toast(message, MessageService.TypeError);
        }

        throw error;
    }

    private enableLoading(showLoading: boolean) {
        if (showLoading) {
            ssmLoadingService.enableLoading();
        }
    }

}

export const ssmHttpService = new HttpService();
